import { ITableMessageInternal } from "../../interfaces";
import { IDeveloperField } from "../schemas/fields";
import {
  IRequireWithValidator,
  IRequireWithValuesValidator,
  IValidatorField,
} from "../schemas/validators";
import { cellIsEmpty } from "../TableHelpers";
import { getValidatorMessage } from "../Validators";

export default function validateRequireWith(
  validator: IRequireWithValidator | IRequireWithValuesValidator,
  cellValue: unknown,
  rowData: unknown[],
  columnMapping: Map<number, IDeveloperField>
): ITableMessageInternal[] {
  // if we have a cellValue, then the required validation passes
  if (!cellIsEmpty(cellValue)) return [];

  let otherKeys: string[];
  const valuesAtKeysMatch = (key: string): boolean =>
    // eslint-disable-next-line eqeqeq
    rowValuesObj[key] ==
    (validator as IRequireWithValuesValidator).fieldValues[key];

  if (isRequireWithValidator(validator)) {
    // guard against invalid validator
    if (validator.fields == null) return [];

    otherKeys = validator.fields;
  } else {
    // guard against invalid validator
    if (validator.fieldValues == null) return [];

    otherKeys = Object.keys(validator.fieldValues);
  }

  let validateFn: (keys: string[]) => boolean;
  switch (validator.validate) {
    case "require_with":
      validateFn = () => rowValuesArray.every(cellIsEmpty);
      break;
    case "require_with_values":
      validateFn = (keys) => !keys.some(valuesAtKeysMatch);
      break;
    case "require_without":
      validateFn = () => !rowValuesArray.some(cellIsEmpty);
      break;
    case "require_without_values":
      // this one is weird
      validateFn = (keys) =>
        keys.every(
          (key) =>
            cellIsEmpty(rowValuesObj[key]) ||
            rowValuesObj[key] === validator.fieldValues[key]
        );
      break;
    case "require_with_all":
      validateFn = () => rowValuesArray.some(cellIsEmpty);
      break;
    case "require_with_all_values":
      validateFn = (keys) => !keys.every(valuesAtKeysMatch);
      break;
    case "require_without_all":
      validateFn = () => !rowValuesArray.every(cellIsEmpty);
      break;
    case "require_without_all_values":
      validateFn = (keys) => keys.some(valuesAtKeysMatch);
      break;
  }

  const rowValuesArray = getValuesArrayForFieldKeys(
    otherKeys,
    columnMapping,
    rowData
  );
  const rowValuesObj = getValuesObjForFieldKeys(
    otherKeys,
    columnMapping,
    rowData
  );
  const isValid = validateFn(otherKeys);

  if (!isValid) {
    return [getValidatorMessage(validator, requireWithErrorMessage(validator))];
  } else {
    return [];
  }
}

export const requireWithValidators = [
  "require_with",
  "require_without",
  "require_with_all",
  "require_without_all",
];

export const requireWithValuesValidators = [
  "require_with_values",
  "require_without_values",
  "require_with_all_values",
  "require_without_all_values",
];

function getValuesArrayForFieldKeys(
  fields: string[],
  columnMapping: Map<number, IDeveloperField>,
  rowData: unknown[]
): unknown[] {
  const values: unknown[] = [];

  columnMapping.forEach((field, colIndex) => {
    if (fields.includes(field.key)) {
      values.push(rowData[colIndex]);
    }
  });

  return values;
}

function getValuesObjForFieldKeys(
  fields: string[],
  columnMapping: Map<number, IDeveloperField>,
  rowData: unknown[]
): { [key: string]: unknown } {
  // map of columnKey -> columnIndex
  const fieldColIndexes: { [key: string]: number } = {};

  columnMapping.forEach((field, colIndex: number) => {
    if (!field.manyToOne && fields.includes(field.key)) {
      fieldColIndexes[field.key] = colIndex;
    }
  });

  return Object.fromEntries(
    fields.map((f) => [f, rowData[fieldColIndexes[f]]])
  );
}

function requireWithErrorMessage(
  validator: IRequireWithValidator | IRequireWithValuesValidator
): string {
  if (validator.errorMessage) return validator.errorMessage;

  let fields: string;
  if (isRequireWithValidator(validator)) {
    fields = validator.fields.join(", ");
  } else {
    fields = Object.entries(validator.fieldValues)
      .map(([key, value]) => `${key} = ${value}`)
      .join(", ");
  }

  switch (validator.validate) {
    case "require_with":
      return `Field must be present if any of these fields are filled: ${fields}`;
    case "require_without":
      return `Field must not be empty if any of these fields are empty: ${fields}`;
    case "require_with_all":
      return `Field must not be empty if all of these fields are present: ${fields}`;
    case "require_without_all":
      return `Field must not be empty if all of these fields are empty: ${fields}`;
    case "require_with_values":
      return `Field must be present if any of these fields are filled with the following values: ${fields}`;
    case "require_without_values":
      return `Field must be present if any of these fields are present and NOT filled with the following values: ${fields}`;
    case "require_with_all_values":
      return `Field must be present if all of these fields are filled with the following values: ${fields}`;
    case "require_without_all_values":
      return `Field must be present if all of these fields are present and NOT filled with the following values: ${fields}`;
  }
}

// helper function to assert between IRequireWithValidator / IRequireWithValueValidator
function isRequireWithValidator(
  validator: IValidatorField
): validator is IRequireWithValidator {
  return requireWithValidators.includes(validator.validate);
}

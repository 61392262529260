import React, { useState, useEffect } from "react";

import Modal from "../commonComponents/Modal";
import Select from "react-select";
import { useAppDispatch, useAppSelector, useSettings } from "../../store/hooks";
import { setSelectedSheet } from "../../store/reducers/coredata";
import { useTranslation } from "react-i18next";
import { IWorksheet } from "../../interfaces";
import Text from "../commonComponents/Text";
import { Button } from "../commonComponents/Button";

interface ISelectSheetProps {
  show: boolean;
  onHide: (selectedSheetId: string) => void;
}

export function SelectSheetModal(props: ISelectSheetProps): JSX.Element | null {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const sheets = useAppSelector((state) => state.coredata.sheets);
  const [sheet, setSheet] = useState(sheets?.[0]);
  const {
    uploadStep: { sheetSelectionHelpText },
  } = useSettings();

  // handle race condition where the modal is rendered before sheets are set in redux state
  useEffect(() => {
    if (sheet === undefined && sheets && sheets.length > 0) {
      setSheet(sheets[0]);
    }
  }, [sheet, sheets]);

  if (!sheet || !sheets || sheets.length === 0) return null;

  const onChange = (option: IWorksheet | null) => {
    if (!option) return;
    setSheet(option);
  };

  const onConfirm = () => {
    dispatch(setSelectedSheet(sheet.id));
    props.onHide(sheet.id);
  };

  return (
    <Modal
      show={props.show}
      onClose={onConfirm}
      centered
      className="fileUploadModal"
      data-cy="select-sheet-modal"
    >
      <div className="shadow rounded-lg p-6 w-96">
        <Text type="display">
          {t("dataUploadModal.fileUpload.selectSheet")}
        </Text>
        {sheetSelectionHelpText && (
          <Text type="body" className="my-2">
            <i>{sheetSelectionHelpText}</i>
          </Text>
        )}
        <Select
          // Data-cy doesn't work with react-select
          className="mt-2 select-sheet-select"
          classNamePrefix="select-sheet"
          options={sheets}
          onChange={onChange}
          value={sheet}
          getOptionLabel={(opt) => opt.label}
          getOptionValue={(opt) => opt.id}
        />
        <Button
          onClick={onConfirm}
          className="w-full mt-6"
          data-cy="select-sheet-continue-button"
        >
          {t("common.continue")}
        </Button>
      </div>
    </Modal>
  );
}

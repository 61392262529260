import type XLSX from "@sheet/core";
import Papa from "papaparse";
import { DateTime } from "luxon";
import { roundToNearestSecond } from "../util/datetime";
import { FullDataWithMeta } from "../util/data_actions";
import jschardet from "jschardet";

export interface DataStats {
  valCountsInColumn: Map<number, Map<string, number>>;
  percentHasValueInColumn: Map<number, number>;
}

function isValidHttpUrl(string: string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

/**
 * transposes the rows and columns in an array of arrays
 * all rows are assumed to have the same length
 * @example
 * transpose([["a", "b"], ["c", "d"], ["e", "f"]])
 * // returns  [["a", "c", "e"], ["b", "d", "f"]]
 **/
export function transpose<T>(data: T[][] | null): T[][] {
  if (data === null) return [];
  if (data.length === 0) return [];

  return data[0].map((_: any, colIndex: number) =>
    data.map((row) => row[colIndex])
  );
}

export function getColumnValueCounts(
  data: string[][] | FullDataWithMeta,
  maxMappableSelectValues: number,
  headerRow = -1,
  stripMeta = false
): Map<number, Map<string, number>> {
  if (data.length === 0 || data[0].length === 0) {
    return new Map();
  }

  // mapping of colIndex to a set of unique values, which we can count
  const valCountsInColumn = new Map<number, Map<string, number>>();

  // if a column is over the max number of unique values, we should stop tracking value counts.
  // we can't do select matching and we don't want to use a ton of memory to store it
  const columnsWithExcessUniqueValues = new Set<number>();

  for (const colIndex of data[0].keys()) {
    valCountsInColumn.set(colIndex, new Map());
  }

  data.forEach((row, rowIdx) => {
    if (rowIdx <= headerRow) return;

    row.forEach((val, colIdx) => {
      if (
        !columnsWithExcessUniqueValues.has(colIdx) &&
        !(stripMeta && colIdx === row.length - 1)
      ) {
        if (val !== "" && val !== null && val !== undefined) {
          let valCounts = valCountsInColumn.get(colIdx);

          // Unclear why this is needed - we're getting ragged rows somehow
          if (!valCounts) {
            valCounts = new Map();
            valCountsInColumn.set(colIdx, valCounts);
          }

          const count = valCounts.get(val as string);
          valCounts.set(val as string, count === undefined ? 1 : count + 1);

          if (valCounts.size > maxMappableSelectValues)
            columnsWithExcessUniqueValues.add(colIdx);
        }
      }
    });
  });

  // Remove these because we don't want to show too many unique values
  for (const colIdx of columnsWithExcessUniqueValues) {
    valCountsInColumn.delete(colIdx);
  }

  return valCountsInColumn;
}

export function parseCSV(
  // Can either accept a "File" or "String" which represents a remote file URL
  file: File | string,
  encoding: string,
  delimiter: string | undefined
): Promise<string[][]> {
  return new Promise((resolve, reject) => {
    // @ts-expect-error Typings are wrong here
    Papa.parse(file, {
      // download = true if file is a URL (typeof string)
      download: typeof file === "string" && isValidHttpUrl(file),
      encoding,
      header: false,
      dynamicTyping: false,
      skipEmptyLines: true,
      delimiter,
      complete: (results) => {
        resolve(results.data as string[][]);
      },
      error: (error) => reject(error),
    });
  });
}

export async function parseWorksheet(
  worksheet: XLSX.WorkSheet
): Promise<string[][]> {
  if (worksheet["!ref"] === undefined) {
    // Means we have a completely empty sheet
    return [];
  }

  const XLSX = await import("@sheet/core");
  const rowLength = XLSX.utils.decode_range(worksheet["!ref"]!).e.c + 1;

  return Array.from(worksheet["!data"] as XLSX.CellObject[][]).map(
    (row: XLSX.CellObject[] | undefined) => {
      if (row === undefined) {
        return new Array(rowLength).fill("");
      }

      // Merged cells result in a sparse array.
      // The Array.from converts empty elements to undefined so we can map over them.
      let filledRow = Array.from(row);

      // Each row will only have as many elements as its last filled cell, but we
      // need them to have uniform length.
      if (filledRow.length < rowLength) {
        const padding = new Array(rowLength - filledRow.length).fill(undefined);
        filledRow = filledRow.concat(padding);
      }

      return filledRow.map((cell) => {
        if (cell?.v === undefined) return "";

        switch (cell.t) {
          case "n": // number
          case "b": // boolean
            // We return the raw value, instead of display value.
            // For numbers, large values can get truncated into scientific notation
            // in the display value.
            return cell.v.toString();
          case "d": {
            // Dates are converted to ISO-8601 strings.
            // The excel formatted value is locale-dependent.

            // We round to the nearest second to fix rounding issues
            const dateTime = roundToNearestSecond(
              DateTime.fromJSDate(cell.v as Date).setZone("UTC")
            );

            const dateString = dateTime.toISODate();
            if (dateString === "1899-12-30" || dateString === "1899-12-31") {
              // this means we have a plain clock time, discard the date
              return dateTime.toISOTime({
                includeOffset: false,
                suppressMilliseconds: true,
              });
            } else {
              return dateTime.toISO({
                includeOffset: false,
                suppressMilliseconds: true,
              });
            }
          }
          default: {
            // Default is to return the formatted value if it exists, otherwise the raw value.
            // CSVs only have raw values.
            let value = cell.w ?? cell.v;

            // String should be the only type left here, but I don't trust sheetjs
            if (typeof value === "string") {
              value = value.trim();
            }

            return value;
          }
        }
      });
    }
  );
}

export async function parseWorkbook(
  file: File | string,
  sheetName: string
): Promise<XLSX.WorkBook> {
  const opts: XLSX.ParsingOptions = {
    cellDates: true,
    dense: true,
    sheets: [sheetName],
  };

  if (process.env.JS_PLATFORM === "headless") {
    const { sheetJSRead } = await import("../headless/file_parsing");
    return sheetJSRead(file as string, opts);
  } else {
    return sheetJSRead(file as File, opts);
  }
}

export async function getSheetNames(file: File | string): Promise<string[]> {
  const opts: XLSX.ParsingOptions = { bookSheets: true };
  let workbook: XLSX.WorkBook;

  if (process.env.JS_PLATFORM === "headless") {
    const { sheetJSRead } = await import("../headless/file_parsing");
    workbook = await sheetJSRead(file as string, opts);
  } else {
    workbook = await sheetJSRead(file as File, opts);
  }
  return workbook.SheetNames;
}

async function sheetJSRead(
  file: File,
  opts: XLSX.ParsingOptions
): Promise<XLSX.WorkBook> {
  const XLSX = await import("@sheet/core");
  const buffer = await file.arrayBuffer();
  return XLSX.read(buffer, opts);
}

export const cellIsEmpty = (cellValue: unknown): boolean => {
  return cellValue === undefined || cellValue === null || cellValue === "";
};

const CHARDET_MAX_BUFFER = 1e7; // 10 MB

export async function detectEncoding(file: File): Promise<string | null> {
  // we attempt to guess the correct encoding with jschardet, but in order for it to
  // work properly, we need to pass it a "binary string" (i.e. a string of raw bytes)
  // we need to go from array buffer -> Uint8Array -> string for this to work.
  const buffer = await file.arrayBuffer();
  // For huge files, we don't want to load the entire file into memory for
  // encoding detection, so we max out at 10MB. It's unclear how much is actually
  // needed, this is probably a very conservative guess
  const binaryString = new Uint8Array(
    buffer,
    0,
    Math.min(buffer.byteLength, CHARDET_MAX_BUFFER)
  ).reduce((str, char) => str + String.fromCharCode(char), "");

  return jschardet.detect(binaryString).encoding;
}

// note this method does all changes in-place to avoid large copies
export const cleanRawData = (
  data: string[][],
  removeEmptyRows = true
): void => {
  // coerce values to string and strip whitespace
  const longestRowLength = data.reduce(
    (max, row) => Math.max(row.length, max),
    0
  );
  data.forEach((row) => {
    for (let colIdx = 0; colIdx < longestRowLength; colIdx++) {
      const value = row[colIdx];
      if (value === null || value === undefined) {
        row[colIdx] = "";
      } else {
        row[colIdx] = value.toString().trim();
      }
    }
  });

  if (removeEmptyRows) {
    for (let i = data.length - 1; i >= 0; i--) {
      if (data[i].every((v) => v === "")) {
        data.splice(i, 1);
      }
    }
  }
};

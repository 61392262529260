import { IAbstractField } from "../../fields";
import { INewTableMessages } from "../../interfaces";
import MapWithDefault from "../../util/MapWithDefault";
import { IUniqueValidator } from "../schemas/validators";
import { cellIsEmpty } from "../TableHelpers";
import { getValidatorMessage } from "../Validators";

export default function validateUnique(
  validator: IUniqueValidator,
  field: IAbstractField,
  colIndex: number,
  colData: unknown[],
  newTableMessages: INewTableMessages,
  transformErrorCells: Set<string>
): void {
  const valueOccurances = new MapWithDefault<unknown, Set<string>>(
    () => new Set()
  );

  // For unknown reasons (possibly adding/deleting/copying rows in review modal)
  // sometimes this can be undefined, so don't loop over it
  if (colData === undefined) return;

  colData.forEach((cellValue, rowIndex) => {
    if (cellIsEmpty(cellValue)) return;

    const key = `${rowIndex},${colIndex}`;

    if (!transformErrorCells.has(key))
      cellValue = field.getDisplayValue(cellValue, rowIndex);

    if (
      validator.validate === "unique_case_insensitive" &&
      typeof cellValue === "string"
    ) {
      cellValue = cellValue.toLowerCase();
    }

    valueOccurances.get(cellValue).add(key);
  });

  const newMessage = getValidatorMessage(validator, "Value must be unique");

  valueOccurances.forEach((keys) => {
    if (keys.size > 1) {
      keys.forEach((key) => {
        const [row, col] = key.split(",").map((v) => parseInt(v));
        newTableMessages.push([row, col, [newMessage]]);
      });
    }
  });
}

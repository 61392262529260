import React from "react";
import { twMerge } from "tailwind-merge";
import { SingleValueProps, components } from "react-select";
import Text from "../Text";
import { useSelectContext } from "./context";
import { getMatchPill } from "../Pill";
import { IgnoredLabel } from "./IgnoredLabel";
import { truncate } from ".";

export const SingleValue = (props: SingleValueProps<any>) => {
  const { isIgnored } = useSelectContext();
  const value = props.getValue()[0];
  const hasValue = typeof value?.label === "string";

  return (
    <components.SingleValue
      {...props}
      className={twMerge(props.className, "relative !text-sm")}
    >
      <span
        className="inline-flex gap-3 w-full justify-between truncate items-center"
        data-cy="select-selected-value"
      >
        {isIgnored ? (
          <IgnoredLabel />
        ) : (
          hasValue && (
            <Text
              type="body"
              as="span"
              className="flex gap-2 justify-between items-center w-full"
            >
              <Text type="body" as="span">
                {truncate(
                  value.label,
                  Math.max(
                    40,
                    Math.min(
                      typeof window !== "undefined"
                        ? window.innerWidth / 15
                        : 40,
                      100
                    )
                  )
                )}
              </Text>
              {value?.matchType?.length > 0 && getMatchPill(value.matchType)}
            </Text>
          )
        )}
      </span>
    </components.SingleValue>
  );
};

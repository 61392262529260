import { ITableMessageInternal } from "../../interfaces";
import { ILengthValidator } from "../schemas/validators";
import { getValidatorMessage } from "../Validators";

export default function validateLength(
  validator: ILengthValidator,
  cellValue: unknown
): ITableMessageInternal[] {
  if (typeof cellValue !== "string" || cellValue === "") return [];

  if (validator.min !== undefined && validator.max !== undefined) {
    return cellValue.length < validator.min || cellValue.length > validator.max
      ? [
          getValidatorMessage(
            validator,
            `Length must be at least ${validator.min} and at most ${validator.max}`
          ),
        ]
      : [];
  } else if (validator.min !== undefined) {
    return cellValue.length < validator.min
      ? [
          getValidatorMessage(
            validator,
            `Length must be at least ${validator.min}`
          ),
        ]
      : [];
  } else {
    return cellValue.length > validator.max!
      ? [
          getValidatorMessage(
            validator,
            `Length must be at most ${validator.max}`
          ),
        ]
      : [];
  }
}

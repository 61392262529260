import { INewTableMessages } from "../../interfaces";
import MapWithDefault from "../../util/MapWithDefault";
import { IUniqueWithValidator } from "../schemas/validators";
import { getValidatorMessage, IUniqueWithDict } from "../Validators";

export default function validateUniqueWith(
  uniqueWithColumnMap: MapWithDefault<
    string,
    Set<[number, IUniqueWithValidator]>
  >,
  globalDict: IUniqueWithDict,
  newTableMessages: INewTableMessages
): void {
  // for each unique key
  globalDict.forEach((valueMap, uniqueKey) => {
    // for each unique set of values for that key
    valueMap.forEach((rowIndexes) => {
      // if only one row has that set of values, validation passes
      if (rowIndexes.size <= 1) return;

      // otherwise add an error to each cell
      rowIndexes.forEach((rowIndex) => {
        uniqueWithColumnMap.get(uniqueKey)!.forEach(([colIndex, validator]) => {
          const message = getValidatorMessage(
            validator,
            `Values are not unique for ${validator.uniqueKey}`
          );
          newTableMessages.push([rowIndex, colIndex, [message]]);
        });
      });
    });
  });
}

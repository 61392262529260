import { WorkerMessage, IProcessChangesMessage } from "./types";
import { FullDataWithMeta, HotChange } from "../util/data_actions";
import { AppDispatch } from "../store/configureStore";
import {
  setCellTransformErrors,
  setTableMessages,
} from "../store/reducers/coredata";
import { batch } from "react-redux";

let worker: Worker | null = null;
let currentCallback: ((data: FullDataWithMeta) => void) | null = null;
let currentErrorCallback: ((error: Error) => void) | null = null;
let dispatchFn: AppDispatch | null = null;

export const initWorker = (
  dispatch: AppDispatch,
  resolve: (data: FullDataWithMeta) => void,
  reject: (error: Error) => void
) => {
  dispatchFn = dispatch;
  currentCallback = resolve;
  currentErrorCallback = reject;

  if (!worker) {
    worker = new Worker(
      new URL("./process_changes.worker.ts", import.meta.url)
    );

    worker.onmessage = (e: MessageEvent<WorkerMessage>) => {
      if (e.data.type === "PROCESS_CHANGES_COMPLETE" && dispatchFn) {
        const { newFullData, newTableMessages, newTransformErrorCells } =
          e.data.data;

        if (currentCallback) {
          currentCallback(newFullData);
        }

        batch(() => {
          dispatchFn!(setCellTransformErrors(newTransformErrorCells));
          dispatchFn!(setTableMessages(newTableMessages));
        });
      }
    };

    worker.onerror = (error) => {
      if (currentErrorCallback) {
        currentErrorCallback(
          error instanceof Error ? error : new Error(String(error))
        );
      }
    };
  }

  return worker;
};

export const processChangesInWorker = (
  fullData: FullDataWithMeta,
  changes: HotChange[],
  dispatch: AppDispatch
): Promise<FullDataWithMeta> => {
  return new Promise((resolve, reject) => {
    try {
      const worker = initWorker(dispatch, resolve, reject);

      // Get developer fields from the state
      const state = dispatch((_, getState) => getState());
      const highlightAutoFixes = state.settings.reviewStep.highlightAutoFixes;
      const fieldSpecs = state.fields.fieldSpecs;
      const columnMapping = state.fields.columnMapping;
      const selectOptionOverrides = state.coredata.selectOptionOverrides;
      const tableMessages = state.coredata.tableMessages;
      const transformErrorCells = state.coredata.transformErrorCells;

      const message: IProcessChangesMessage = {
        type: "PROCESS_CHANGES",
        data: {
          fullData,
          changes,
          fieldSpecs,
          columnMapping,
          selectOptionOverrides,
          tableMessages,
          transformErrorCells,
          highlightAutoFixes,
        },
      };

      worker.postMessage(message);
    } catch (error) {
      currentErrorCallback!(
        error instanceof Error ? error : new Error(String(error))
      );
    }
  });
};

export const terminateWorker = () => {
  if (worker) {
    worker.terminate();
    worker = null;
    currentCallback = null;
    dispatchFn = null;
  }
};

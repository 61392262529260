import { ITableMessageInternal } from "../../interfaces";
import { IRequiredValidator } from "../schemas/validators";
import { cellIsEmpty } from "../TableHelpers";
import { getValidatorMessage } from "../Validators";

export default function validateRequired(
  validator: IRequiredValidator,
  cellValue: unknown
): ITableMessageInternal[] {
  if (cellIsEmpty(cellValue)) {
    return [getValidatorMessage(validator, "Required")];
  } else {
    return [];
  }
}

import { ITableMessageInternal } from "../../interfaces";
import { IRegexValidator } from "../schemas/validators";
import { getValidatorMessage } from "../Validators";

export default function validateRegex(
  validator: IRegexValidator,
  cellValue: unknown
): ITableMessageInternal[] {
  if (typeof cellValue !== "string" || cellValue === "") return [];

  const defaultMessage =
    validator.validate === "regex_match"
      ? "Does not match regex"
      : "Must exclude regex";

  let regexOptions = "";

  if (validator.regexOptions?.ignoreCase) regexOptions += "i";
  if (validator.regexOptions?.dotAll) regexOptions += "s";
  if (validator.regexOptions?.multiline) regexOptions += "m";
  if (validator.regexOptions?.unicode) regexOptions += "u";

  const cellMatches = new RegExp(validator.regex, regexOptions).test(cellValue);
  if (
    (!cellMatches && validator.validate === "regex_match") ||
    (cellMatches && validator.validate === "regex_exclude")
  ) {
    return [getValidatorMessage(validator, defaultMessage)];
  } else {
    return [];
  }
}

import { IDeveloperField, ITableMessage, IDeveloperSettings } from ".";

export type TBackendType = "AWS" | "AZURE";

export interface ITableMessageInternal extends ITableMessage {
  type:
    | "user-generated"
    | "field-transform"
    | "validation"
    | "transform-highlight";
  // user-generated: added by the user in a hook
  // field-transform: the raw value could not be parsed by the field's transform function
  // validation: generated by a failed validation on a field
}

export type ITableMessages = Map<number, Map<number, ITableMessageInternal[]>>;

// Uploaded file data & metadata interfaces

export type UPLOAD_TYPE =
  | "FILE"
  | "MANUAL_INPUT"
  | "INITIAL_DATA_MAPPED"
  | "INITIAL_DATA_UNMAPPED"
  | "INITIAL_FILE";

export interface IData {
  // For Headless, file will be a local file path. In Browser, it will be a WebAPI File object
  file: File | string | null;
  uploadType: UPLOAD_TYPE;
  valCountsInColumn: Map<number, Map<string, number>> | null;
  dateFormats: Map<number, DateSpec> | null;
  previewData: any[][];
  rawPreviewData: any[][];
}

export interface INewCellValue {
  row: number;
  column: number;
  value: any;
}

export interface INewCellData {
  row: number;
  column: number;
  value: any;
}

export interface IProgress {
  message?: string;
  count: number;
}

export interface IBackendCapabilities {
  accept_json_results: boolean;
  write_only_storage: boolean;
  saved_schema_only: boolean;
  ai_matching: boolean;
  transform_data?: boolean;
  row_limit?: number;
  schema_limit?: number;
  has_dromo_branding?: boolean;
  allow_js_schema?: boolean;
  allow_style_overrides?: boolean;
  allow_user_transforms?: boolean;
  allow_hooks?: boolean;
  allow_js_results?: boolean;
  allow_help_text?: boolean;
  allow_initial_data?: boolean;
  allow_custom_fields?: boolean;
  allow_excel_export?: boolean;
  allow_auto_map_headers?: boolean;
  allow_i18n?: boolean;
  auto_date_fix?: boolean;
  ai_many_to_one_matching?: boolean;
  allow_worker_processing?: boolean;
}

export interface ISavedSchema {
  id: string;
  name: string;
  fields: IDeveloperField[];
  settings: Partial<IDeveloperSettings>;
}

export interface IWorksheet {
  id: string;
  label: string;
}

export interface IRowMeta {
  originalIndex: number | null;
  rowId: string;
}

export type INewTableMessages = [
  row: number,
  col: number,
  messages: ITableMessageInternal[]
][];

export enum MATCH_TYPES {
  AI_SUGGESTED = "columnMatchModalCard.aiSuggestedChip",
  AUTO_MATCHED = "columnMatchModalCard.autoMatchedChip",
  USER_MATCHED = "columnMatchModalCard.userMatchedChip",
  UNMATCHED = "columnMatchModalCard.unmatched",
  IGNORED = "columnMatchModalCard.ignoredChip",
}

export interface DateSpec {
  day: { index: number };
  month: { index: number; format: "number" | string[] };
  year: { index: number; format: "two-digit" | "four-digit" };
}

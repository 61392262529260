import { ITableMessageInternal } from "../../interfaces";
import { IAlphabeticalValidator } from "../schemas/validators";
import { getValidatorMessage } from "../Validators";

export default function validateAlphabetical(
  validator: IAlphabeticalValidator,
  cellValue: unknown
): ITableMessageInternal[] {
  if (typeof cellValue !== "string" || cellValue === "") return [];

  const defaultMessage = "Must contain only letters";

  const cellMatches = /^[a-zA-Z]+$/.test(cellValue);
  if (!cellMatches) {
    return [getValidatorMessage(validator, defaultMessage)];
  } else {
    return [];
  }
}
